// 匿名用户的modal
.anonymous-modal{
  .from-item{
    width:100%;
    .item-input{
      width:100%;
    }
    .item-avatar{
      width:100%;
      text-align:center;
    }
  }
  .modal-footer{
    width:100%;
    text-align:center;
  }
}