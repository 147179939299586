@import '~@/styles/_variables.scss';

:root {
  --swiper-navigation-size: 30px;
  --swiper-theme-color: #fff;
}

.video-talk{
  width:100%;
  height:100%;
  position: relative;
  display: flex;
  flex-direction: column;
  .stream-actions{
    position: absolute;
    top:10px;
    right:10px;
    z-index:99;
  }
  .stream-container{
    width:100%;
    height:100%;
    display:flex;
    align-items:flex-start;
    justify-content:flex-start;
    flex-wrap:wrap;
    &.__hasPPT{
      width:100%;
      flex-wrap:nowrap;
      height: 140px;
      // overflow-x:scroll;
      .stream-item{
        height:100% !important;
      }
    }
    .stream-swiper{
      width:100%;
      height:100%;
      .stream-item{
        width:100%;
        height:100%;
        // border:1px solid red;
        &.stream__1{
          width:100%;
        }
        &.stream__2,
        &.stream__3,
        &.stream__4{
          width:50%;
        }
        &.stream__3,
        &.stream__4{
          height:50%;
        }
        &.stream__5,
        &.stream__6,
        &.stream__7,
        &.stream__8,
        &.stream__9{
          width:100%/3;
        }
        &.stream__5,
        &.stream__6{
          height:50%;
        }
        &.stream__7,
        &.stream__8,
        &.stream__9{
          height:100%/3;
        }
        @media screen and (max-width: $screen-sm){
          &.stream__1,
          &.stream__2{
            width:100% !important;
          }
          &.stream__2{
            height:50% !important;
          }
          &.stream__3{
            width:100% !important;
            height:100%/3 !important;
          }
          &.stream__4{
            width:50%!important;
            height:50%!important;
          }
          &.stream__5,
          &.stream__6{
            width:50% !important;
            height:100%/3 !important;
          }
          &.stream__7,
          &.stream__8{
            width:50%;
            height:100%/4;
          }
          &.stream__9{
            width:100%/3;
            height:100%/3;
          }
        }
      }
    }
  }
  .ppt-area{
    width:100%;
    height:calc(100% - 140px);
    background-color: #f5f5f5;
    .show-ppt{
      width:100%;
      height:100%;
      // background-repeat:no-repeat;
      // background-position:center center;
      // background-size:contain;
      position: relative;
      background-color:rgba($color: #000000, $alpha: 0.7);
    }
  }
}
