.network-quality-container {
  height: 26px;
  display: flex;
  align-items: center;
  position: absolute;
  left:0;
  top: 0;
  .arrow {
    width: 14px;
    height: 20px;
  }
  .network-quality {
    height: 18px;
    width: 20px;
    margin-right: 8px;
    display: flex;
    align-items: flex-end;
    justify-content: space-around;
    .network-quality-1 {
      width: 3px;
      height: 20%;
      background-color: #fff;
    }
    .network-quality-2 {
      width: 3px;
      height: 40%;
      background-color: #fff;
    }
    .network-quality-3 {
      width: 3px;
      height: 60%;
      background-color: #fff;
    }
    .network-quality-4 {
      width: 3px;
      height: 80%;
      background-color: #fff;
    }
    .network-quality-5 {
      width: 3px;
      height: 100%;
      background-color: #fff;
    }
    .green {
      background-color: #00FF02;
    }
  }
}

.bar {
  width: 100%;
  max-width:280px;
  // height: 40px;
  padding:10px 15px;
  border-radius:6px;
  background-color:rgba(44,44,44,.6);
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  bottom: 0;
  left:0;
  .bar-icon{
    font-size:26px !important;
    color:#fff;
  }
  &.bar__mini{
    width:100%;
    max-width:100%;
    padding:6px 12px;
    border-radius:3px;
    .bar-icon{
      font-size:20px !important;
    }
    .user-info{
      .user-name{
        font-size: 14px;
      }
      .user-company{
        font-size: 12px;
      }
    }
  }
}
.local {
  justify-content: flex-end;
}
.pointer {
  cursor: pointer;
}

// 音频
.audio-volume-container {
  position: relative;
  line-height:1;
  .volume-container {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    transition: height .1s ease;
    .__green{
      position: absolute;
      bottom: 0;
      color: #1afa29;
    }
  }
}

// 用户信息
.user-info{
  flex:1;
  text-align:left;
  .user-name{
    font-size: 16px;
    color: #ffffff;
  }
  .user-company{
    font-size: 12px;
    color: rgba(255,255,255,.6);
  }
}