.user-info{
  width:100%;
  border-radius:10px;
  background-color:#fff;
  .basic-info{
    width:100%;
    display:flex;
    flex-direction: column;
    justify-content:center;
    align-items:center;
    padding:5px 0 20px 0;
    .user-name{
      font-size:18px;
      color: #000000;
      margin-bottom:0;
    }
    .company-name{
      font-size:12px;
      color: #666666;
      margin-bottom:0;
    }
  }
  .info-list{
    border-top:1px solid #ececec;
    padding:16px 0;
    .list__item{
      padding:10px 0;
      font-size:14px;
      color:#666;
      display:flex;
      align-items:center;
      .item__icon{
        font-size:16px;
        padding-right:10px;
      }
      .item__conent{
        flex:1;
        display:flex;
        align-items:center;
      }
      .item__lable{
        word-break: keep-all;
      }
      .item__value{
        flex:1;
        padding-left:10px;
        color:#333;
        word-break: break-word;
      }
    }
  }
  .card-actions{
    width:100%;
    display:flex;
    justify-content:center;
  }
}