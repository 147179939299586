@import '~@/styles/_variables.scss';

.schedule-item{
  width:100%;
  display:flex;
  padding:8px 0;
  .item__time{
    font-size:16px;
    color:$primary-color;
  }
  .item__info{
    width:0;
    flex:1;
    padding-left:16px;
    word-wrap: break-word;
    .user__name{
      color:#333;
      font-size:16px;
      line-height:1;
      margin-bottom:0;
    }
    .user__company{
      color:#666;
      font-size:14px;
      margin-bottom:0;
      padding-top:0.5em;
    }
  }
}