.item {
  width: 100%;
  height: 100%;
  position: relative;
  // border: 1px solid #000;
  &.item-mini{
    border-left: 1px solid #fff;
  }
  &-view {
    width: 100%;
    height: 100%;

    video {
      width: 100%;
      height: 100%;

      &::-webkit-media-controls-panel {
        display: none;
      }
    }

    &-exits {
      position: absolute;
      z-index: 10;
      box-sizing: border-box;
      background: rgba(0, 0, 0, .2);
      opacity: 0;
      padding: 0 20px;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 40px;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      &:hover {
        opacity: 1;
      }
    }
  }

  .item-control {
    display: block;
  }

  &-control {
    display: none;
    position: absolute !important;
    width: calc(100% - 20px) !important;
    height: calc(100% - 20px) !important;
    top: 10px;
    left: 10px;
    &.control-mini{
      width: 100% !important;
      height: 100% !important;
      top: 0;
      left: 0;
    }
  }

  &-play-btn-container {
    width: 100%;
    height: 100%;
  }

  &-play-btn {
    position: absolute;
    width: 100px;
    height: 100px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
  }
  // 遮盖
  &-cover{
    position: absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    background-color: #222222;
    display:flex;
    justify-content:center;
    align-items:center;
  }
}