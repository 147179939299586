@import '~@/styles/_variables.scss';
.home{
	width:100%;
	height:100%;
	display:flex;
	flex-direction:column;
	justify-content:center;
  align-items:center;
  text-align:center;
	padding:20px;
	.header{
		color:#333333;
		text-align:center;
		.title{
			font-size:28px;
      color:#000;
		}
		.subtitle{
			font-size:14px;
		}
	}
	.features{
    width:100%;
    max-width:360px;
		padding-top:40px;
		display:flex;
		flex-wrap:wrap;
		font-size:14px;
		color:#333333;
		.icon{
			color:$primary-color;
			font-size:60px;
			width:80px;
			height:80px;
			border-radius:50%;
			background: linear-gradient(to top, lighten(#ccffff, 5%) 0%,#ccffff 100%);
			display:flex;
			align-items:center;
			justify-content:center;
		}
		.item{
			width:50%;
			display:flex;
			flex-direction:column;
			align-items:center;
			justify-content:center;
			text-align:center;
			margin-top:40px;
		}
		.name{
			padding-top:10px;
		}
	}
}