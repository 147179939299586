@import "~@/styles/_variables.scss";

// 视频洽谈的设置
.setting-main{
  width:100%;
  display:flex;
  flex-direction:column;
  justify-content:center;
  background-color: #ffffff;
  border-radius: 20px;
  .space__item{
    width:100%;
    display:flex;
    align-items:center;
    justify-content:center;
    +.space__item{
      margin-top:30px;
    }
    &.space__item__footer{
      margin-top:50px;
    }
    .item__cell{
      display:flex;
      align-items:center;
      font-size:18px;
      +.item__cell{
        // border-left:1px solid rgba(0, 0, 0, 0.05);
        &::before{
          content: '';
          display:block;
          width:1px;
          height:20px;
          background-color:rgba(0, 0, 0, 0.05);
          margin:0 20px;
        }
      }
      .cell__lable{
        color:$primary-color;
      }
      .cell__select{
        width:240px;
        margin-left:8px;
      }
    }
  }
}

