.history-page{
  width:100%;
  height:100%;
  max-width:1000px;
  background-color:#fff;
  display:flex;
  flex-direction:column;
  .page-main{
    height: 0;
    flex: 1;
  }
}