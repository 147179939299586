@import '~@/styles/_variables.scss';
.talk{
  width:100%;
  height:100%;
  display:flex;
  flex-direction:column;
}
.talk-layout{
  width:100%;
  // height:calc(100% - #{$header-height});
  flex:1;
  height:0;
  display:flex;
  align-items:center;
  justify-content:center;
  .videotalk-section{
    width:76%;
    height:100%;
    flex:1;
    display:flex;
    flex-direction: column;
    .videotalk{
      width:100%;
      height:100%;
      background-color: #333333;
    }
  }
  .chatroom-section{
    width:24%;
    height:100%;
    max-width:460px;
    min-width:400px;
    &.isEnd{
      width:100%;
      max-width:100%;
    }
  }
  @media screen and (max-width: $screen-sm){
    .videotalk-section{
      width:100%;
    }
    .chatroom-section{
      width:100%;
      max-width:100%;
      min-width:100%;
    }
  }
}
