@import '~@/styles/_variables.scss';

.business-card-detail{
  width:100%;
  height:100%;
  border-left:1px solid #f1f1f1;
  background-color:#fff;
  display:flex;
  flex-direction:column;
  .info-hd{
    display:flex;
    padding:10px;
    background-color:#f5f6fb;
    .hd-title{
      flex:1;
      color:$primary-color;
      margin-bottom:0;
    }
  }
  .info-bd{
    flex:1;
    height:0;
    overflow-y:scroll;
    padding:16px;
  }
}