@import '~@/styles/_variables.scss';

.invite-confirm{
  width:100%;
  .invite-url{
    font-size:14px;
    border: 1px solid rgb(221, 221, 221);
    padding: 10px;
    border-radius: 5px;
    background-color: rgb(241, 241, 241);
  }
  .invite-tip{
    font-size:14px;
    color:#666;
  }
}
