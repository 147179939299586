@import './variables.scss';

html,
body {
  width:100%;
  height:100%;
  background-color:#f2f4f5;
  overflow: hidden;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

img{
  max-width:100%;
}

#root{
  width:100%;
  height:100%;
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
}


// chat-ui样式调整
.ChatFooter{
  background-color:#ffffff;
  border-top:1px solid #f1f1f1;
}

// 洽谈结束不显示聊天底部
.chat-box{
  width:100%;
  height:100%;
  &.isEnd{
    .ChatFooter{
      display:none;
    }
  }
}

// ant-design 样式调整
.ant-modal{
  .ant-modal-content{
    border-radius:20px;
    overflow: hidden;
  }
}
.ant-image{
  .ant-image-img{
    object-fit: contain;
  }
}

// 旋转动画
@keyframes rotate{
  from {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg);
  }
  to {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}

.animate__rotate {
  -webkit-animation-name: rotate;
  animation-name: rotate;
  -webkit-transform-origin: center;
  transform-origin: center;
  animation-timing-function: linear;
}