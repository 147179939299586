@import '~@/styles/_variables.scss';

.header{
  width:100%;
  display:flex;
  justify-content:flex-start;
  align-items:center;
  background-color:$primary-color;
  padding:12px  16px;
  color:#fff;
  border-bottom:1px solid lighten($primary-color, 50%);
  position: relative;
  z-index:9;
  &.type__walkingin{
    color:#333333;
    background-color:#fff;
    border-color:darken($color: #fff, $amount: 5%);
    box-shadow:0 0 8px rgba(0, 0, 0, 0.05);
  }
  .left{
    display:flex;
    align-items:center;
  }
  .center{
    flex:1;
    display:flex;
    justify-content:center;
    align-items:center;
  }
  .right{
    display:flex;
    justify-content:flex-end;
    align-items:center;
  }
  .line{
    width:1px;
    height:20px;
    background-color:rgba(255, 255, 255, 0.1);
  }
  @media screen and (max-width: $screen-sm){
    // .left{
    //   display:none;
    // }
    .finish-btn{
      .finish-text{
        display:none;
      }
    }
  }
}

.invite-confirm{
  width:100%;
  .invite-url{
    font-size:14px;
    border: 1px solid rgb(221, 221, 221);
    padding: 10px;
    border-radius: 5px;
    background-color: rgb(241, 241, 241);
  }
  .invite-tip{
    font-size:14px;
    color:#666;
  }
}

.countdown-box{
  position:fixed;
  top:80px;
  left:16px;
  z-index:99;
  background-color:#ff4d4f;
  padding:10px;
  line-height:1;
  width:80px;
  height:80px;
  border-radius:50%;
  box-shadow:3px 3px 10px rgba(0, 0, 0, 0.3);
  display:flex;
  flex-direction:column;
  align-items:center;
  justify-content:center;
  color:#fff;
  font-size:14px;
  .countdown-icon{
    font-size:16px;
  }
  .countdown__text{
    flex:1;
    display:flex;
    align-items:center;
    justify-content:center;
    position: relative;
  }
  .countdown-num{
    font-size:30px;
    margin-right:3px;
  }
  .countdown-cell{
    position:absolute;
    right:0;
    top:50%;
    transform:translate(100%, -10%);
  }
}
