@import '~@/styles/_variables.scss';

.user-item{
  width:100%;
  display:flex;
  align-items:center;
  .item__basic{
    flex:1;
    padding-right:8px;
  }
  .item__icon{
    color:$primary-color;
  }
}

.loading-box{
  width:100%;
  padding:16px;
  display:flex;
  align-items:center;
  justify-content:center;
  background-color:#fff;
}