@import '~@/styles/_variables.scss';

.chat-verify{
  width:100%;
  height:100%;
  min-height:100%;
  display: flex;
  flex-direction:column;
  justify-content: center;
  align-items: center;
  .verify-hd{
    width:100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding:20px 24px;
    .hd__content{
      flex:1;
      .logo{
        // width:120px;
        height:60px;
        width:auto;
      }
    }
  }
  .content{
    flex:1;
    width:100%;
    // max-width: 350px;
    text-align:center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .logo{
      margin:0 auto;
      .logo__img{
        width:auto;
        height:100px;
      }
      .company__name{
        font-size:18px;
        color:#333;
        margin-top:1em;
      }
    }
  }
  .entry-ways{
    width:100%;
    display:flex;
    align-items:center;
    justify-content:center;
    margin-top:24px;
    .entry_item{
      flex:1;
      max-width:400px;
      padding:0 48px;
      text-align:center;
      +.entry_item{
        border-left:1px solid #e1e1e1;
      }
      &.__qrcode{
        flex:none;
      }
    }
  }
}
