.control-bar{
  width:100%;
  background-color:#141414;
  display:flex;
  align-items:center;
  justify-content:center;
  color:#fff;
  padding:10px 15px;
  .bar-main{
    flex:1;
    width:0;
    align-items:center;
    justify-content:center;
  }
  .btn-icon{
    width:40px;
    height:40px;
    display:flex;
    justify-content:center;
    align-items:center;
    border-radius:50%;
    background-color:#000000;
  }
}