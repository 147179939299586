@import "~@/styles/_variables.scss";

// 进入页面之前的设置
.pre-enter{
  width:100%;
  height:100%;
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
  background-color:#f2f4f5;
  .enter-header{
    text-align:center;
    margin-bottom:50px;
    .enter-title,
    .enter-subtitle{
      margin-bottom:0;
    }
    .enter-title{
      font-size: 36px;
      color: #222222;
    }
    .enter-subtitle{
      font-size: 18px;
      color: rgba(34, 34, 34, 0.6);
    }
  }
  .setting-main{
    width:100%;
    max-width:740px;
    padding:80px 70px;
    display:flex;
    flex-direction:column;
    justify-content:center;
    background-color: #ffffff;
	  border-radius: 20px;
    .space__item{
      width:100%;
      display:flex;
      align-items:center;
      justify-content:center;
      +.space__item{
        margin-top:30px;
      }
      &.space__item__footer{
        margin-top:50px;
      }
      .item__cell{
        display:flex;
        align-items:center;
        font-size:18px;
        +.item__cell{
          // border-left:1px solid rgba(0, 0, 0, 0.05);
          &::before{
            content: '';
            display:block;
            width:1px;
            height:20px;
            background-color:rgba(0, 0, 0, 0.05);
            margin:0 20px;
          }
        }
        .cell__lable{
          color:$primary-color;
        }
        .cell__select{
          width:240px;
          margin-left:8px;
        }
      }
    }
  }
  @media screen and (max-width: $screen-sm){
    padding-left:15px;
    padding-right:15px;
    .enter-header{
      margin-bottom:10px;
      .enter-title{
        font-size: 24px;
      }
      .enter-subtitle{
        font-size: 16px;
      }
    }
    .setting-main{
      padding:30px 15px;
      border-radius: 10px;
      .space__item{
        width:100%;
        display:flex;
        flex-direction:column;
        align-items:flex-start;
        +.space__item{
          margin-top:15px;
        }
        &.space__item__footer{
          margin-top:15px;
          display:flex;
          align-items:center;
        }
        .item__cell{
          width:100%;
          flex-direction:column;
          align-items:flex-start;
          font-size:14px;
          .cell__lable{
            width:100%;
            margin-bottom:0.5em;
          }
          .cell__select{
            width:100%;
            margin-left:0;
          }
          &.__state{
            flex-direction:row;
            align-items:center;
            justify-content:center;
          }
          +.item__cell{
            &::before{
              display:none;
            }
          }
        }
      }
    }
  }
}


