.navbar{
  display:flex;
  padding:5px 15px;
  background-color:#e8f2f7;
  justify-content:flex-end;
  .translate-btn{
    display:flex;
    align-items:center;
    color:rgba($color: #000000, $alpha: 0.5);
    .translate-label{
      padding-left:0.5em;
    }
  }
}

.msg-item{
  padding:0 3px;
  .__user-name{
    margin-bottom:3px;
    font-size:12px;
    color:rgba($color: #000000, $alpha: 0.4);
  }
  &.___right{
    .__user-name{
      text-align:right;
    }
  }
}

.msg-divider{
  width:100%;
}

// 快速
.toolbar{
  padding: 6px var(--gutter);
  display:flex;
  flex-wrap: wrap;
  align-items:center;
  .toolbar-btn{
    position: relative;
    cursor: pointer;
    +.toolbar-btn{
      margin-left:15px;
    }
    .toolbar-icon{
      color:rgba($color: #000000, $alpha: 0.4);
    }
    .upload-input{
      cursor: pointer;
      position:absolute;
      top:0;
      left:0;
      width:100%;
      height:100%;
      opacity:0;
    }
    .uploading{
      position: absolute;
      top:0;
      left:0;
      width:100%;
      height:100%;
      background-color:rgba($color: #fff, $alpha: 0.8)
    }
  }
  .emojis{
    width:100%;
    flex-wrap: wrap;
    align-items: center;
    padding:5px 0;
    display:none;
    &.__show{
      display: flex;
    }
    .emoji_item{
      margin:2px;
    }
  }
}

.emoji{
  width:24px;
  height:24px;
  font-size:0;
  line-height:0;
  &.__msg{
    width:36px;
    height:36px;
    padding:4px;
  }
}