$primary-color: #0c3372; // 品牌颜色
$fixed-content: 1200px; // 页面主体宽度
$header-height: 60px; // 头部的高度

// 媒体查询节点
//== Breakpoints 媒体查询节点
//
//## 响应式节点的定义

$screen-xs:         576px;  //超小屏幕
$screen-sm:         768px;  //小屏幕
$screen-md:         1024px; //中等屏幕
$screen-lg:         1280px; //大屏幕
$screen-xl:         1440px; //超大屏幕
$screen-xxl:        1680px; //超级大屏幕

// 
//## 媒体查询的归类

// $screen:        "only screen";

// $landscape:     "{$screen} and (orientation: landscape)";
// $portrait:      "{$screen} and (orientation: portrait)";

// $xs-up:        "{$screen} and (min-width:{$screen-xs-up})";//577px及以上
// $xs-down:      "{$screen} and (max-width:{$screen-xs})";//576px及以下

// $sm-up:        "{$screen} and (min-width:{$screen-sm-up})";//769px及以上
// $sm-only:      "{$screen} and (min-width:{$screen-xs-up}) and (max-width:{$screen-sm})";//577px-768px区间
// $sm-down:      "{$screen} and (max-width:{$screen-sm})";//768px及以下

// $md-up:        "{$screen} and (min-width:{$screen-md-up})"; //1024px及以上
// $md-only:      "{$screen} and (min-width:{$screen-sm-up}) and (max-width:{$screen-md})"; //769px-1024px区间
// $md-down:      "{$screen} and (max-width:{$screen-md})"; //1024px及以下
// $md-down-low:  "{$screen} and (max-width:{$screen-md-down})"; //1023px及以下

// $lg-up:        "{$screen} and (min-width:{$screen-lg-up})";//1281px及以上
// $lg-only:      "{$screen} and (min-width:{$screen-md-up}) and (max-width:${screen-lg})"; //1025px-1280px区间
// $lg-down:      "{$screen} and (max-width:{$screen-lg})";//1280px及以下

// $xl-up:        "{$screen} and (min-width:{$screen-xl-up})";//1441px及以上
// $xl-only:      "{$screen} and (min-width:{$screen-lg-up}) and (max-width:{$screen-xl})"; //1281px-1440px区间
// $xl-down:      "{$screen} and (max-width:{$screen-xl})";//1440px及以下

// $xxl-up:        "{$screen} and (min-width:{$screen-xxl-up})"; //1681px及以上
// $xxl-only:      "{$screen} and (min-width:{$screen-xl-up}) and (max-width:{$screen-xxl})"; //144px-1680px区间
// $xxl-down:      "{$screen} and (max-width:{$screen-xxl})"; //1680px及以下