.audio-talk{
  width:100%;
  height:100%;
  .stream-container{
    width:100%;
    display:flex;
    flex-wrap:wrap;
    .stream-item{
      width:50%;
      max-width:300px;
    }
  }
}