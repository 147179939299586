@import '~@/styles/_variables.scss';

.walkin{
  width:100%;
  height:100%;
  display:flex;
  .sidebar{
    width:370px;
    display:flex;
    flex-direction:column;
    .room_type{
      font-size:18px;
      color:#fff;
      background-color:$primary-color;
      padding:0.5em 1em;
    }
    .videotalk-section{
      flex:1;
      height:0;
      background-color:#000;
    }
    &.vidoe-open{
      width:50%;
    }
  }
  .room-info{
    flex:1;
    width:0;
    height:100%;
    display:flex;
    flex-direction:column;
  }
  .room{
    width:100%;
    // height:100%;
    flex:1;
    height:0;
    display:flex;
    flex-direction:column;
    .room-body{
      flex:1;
      height:0;
      display:flex;
      .chatroom-section{
        flex:1;
      }
      .detail-section{
        width:460px;
      }
    }
  }
  .no_rooms{
    width:100%;
    height:100%;
    display:flex;
    justify-content:center;
    align-items:center;
  }
  @media screen and (max-width: $screen-xl){
    .sidebar{
      width:300px;
    }
    .room{
      .room-body{
        .detail-section{
          width:380px;
        }
      }
    }
  }
}
