.user-type{
  $color: #cc9966;
  display: inline-block;
  font-size:14px;
  padding:0.5em 0.8em;
  line-height:1;
  border:1px solid $color;
  color:$color;
  border-radius:1.2em;
  &.size__small{
    font-size:12px;
  }
  &.size__large{
    font-size:16px;
  }
  &[data-type='0']{
    $color: #66cc66;
    color:$color;
    border-color:$color;
  }
  &[data-type='2'],
  &[data-type='3']{
    $color: #cc9966;
    color:$color;
    border-color:$color;
  }
  &[data-type='4']{
    $color: #cc66cc;
    color:$color;
    border-color:$color;
  }
}