.icon-button{
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
  opacity:0.9;
  cursor: pointer;
  .__text{
    font-size:12px;
    line-height:1.25;
    transform: scale(0.8);
    opacity:0.8;
  }
  &:hover{
    opacity:1;
    .__text{
      opacity:1;
    }
  }
}