.ppt{
  width:100%;
  height:100%;
  display:flex;
  align-items:center;
  justify-content:center;
  .select-ppt{
    width:60%;
  }
  .ppt-container{
    width:100%;
    height:100%;
    display:flex;
    flex-direction:column;
    .main-swiper-box{
      flex:1;
      height:0;
      width:100%;
      background-color:lighten($color: #333333, $amount:5%);
      .main-swiper{
        width:100%;
        height:100%;
      }
      .swiper-item{
        background-repeat:no-repeat;
        background-position:center center;
        background-size:contain;
      }
    }
    .action-bar{
      height:22%;
      flex-shrink:0;
      min-height:160px;
      display:flex;
      flex-direction:column;
      .bar-hd{
        display:flex;
        align-items:center;
        padding:10px;
        .hd-left{
          flex:1;
        }
        .select-tip{
          font-size: 16px;
          color:rgba($color: #000000, $alpha: 0.7);
        }
      }
      .bar-bd{
        flex:1;
        width:100%;
        padding:10px;
        .bar-swiper{
          width:100%;
          height:100%;
          .swiper-item{
            cursor: pointer;
            width:200px;
            height:100%;
            background-repeat:no-repeat;
            background-position:center center;
            background-size:cover;
            position: relative;
            &.__active{
              &::after{
                content:'';
                display:block;
                padding:absolute;
                top:0;
                left:0;
                width:100%;
                height:100%;
                border: solid 3px rgba(28, 125, 179, 0.4);
              }
            }
          }
        }
      }
    }
  }
}