@import '~@/styles/_variables.scss';

.rooms-list{
  width:100%;
  height:100%;
  background-color:#e9eaef;
  display:flex;
  flex-direction:column;
  .rooms-header{
    font-size:18px;
    color:$primary-color;
    padding:16px;
    margin-bottom:0;
    .header-title{
      padding-left:0.5em;
      margin-bottom:0;
    }
  }
  .rooms-body{
    flex:1;
    height:0;
    // overflow-y:scroll;
    // padding:0 16px;
  }
  .room-item{
    border:1px solid transparent;
    border-left-width:5px;
    background-color:#fff;
    border-radius:10px;
    padding:16px;
    display:flex;
    font-size:16px;
    cursor: pointer;
    +.room-item{
      margin-top:10px;
    }
    &.__cur{
      border-color:#cccccc;
      border-left-color:$primary-color;
      background-color:$primary-color;
      color:#fff;
      .user-company{
        color:darken($color: #fff, $amount: 10%);
      }
    }
    .user-info{
      flex:1;
      display:flex;
      flex-direction:column;
      padding-left:10px;
    }
    .user-hd{
      flex:1;
      display:flex;
      align-items:center;
      .user-name{
        flex:1;
      }
    }
    .user-company{
      font-size:14px;
      color:#999;
    }
  }
  .scroll-box{
    width:100%;
    height:100%;
    .scroll-slide{
      width:100%;
      height:auto;
      padding:0 16px;
    }
  }
}